import React, { useEffect, useState } from "react";
import AddItemStepOne from "./AddItemStepOne";
import AddItemStepTwo from "./AddItemStepTwo";
import AddItemStepThree from "./AddItemStepThree";
import {
  getCurrentWorkspaceId,
  handleUploadImgFile,
  startApiCall,
} from "../../../../../global-functions/globalFunctions";
import ApiCall from "../../../../../api/ApiCall";
import { ProductCategoryRelatedEndPoints } from "../../../../../api/Endpoints";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

function AddItems({
  setitemModal,
  handleGetCategoryItems,
  isEdit,
  slectedItem,
  setisEdit,
  copyItems,
  setCopyItems,
}) {
  const { user } = useSelector((state) => state);
  const { category, id } = useParams();
  const [loader, setloader] = useState(false);
  const [image, setimage] = useState(null);
  const [materialsInItem, setmaterialsInItem] = useState([]);
  const [dishName, setdishName] = useState("");
  const [dishDescription, setdishDescription] = useState("");
  const [dishTypeVeg, setdishTypeVeg] = useState(true);
  const [costPrice, setcostPrice] = useState("");
  const [sellingPrice, setsellingPrice] = useState("");
  const [errorMessage, seterrorMessage] = useState("");
  const [materialsInTable, setmaterialsInTable] = useState([]);
  const [timetoCook, setTimetoCook] = useState("");
  const completeFirstStep = dishName;
  const completeSecondStep = materialsInItem.length > 0;
  const completeThirdStep = costPrice && sellingPrice && timetoCook;

  useEffect(() => {
    if (copyItems?.items?.length > 0) {
      setmaterialsInTable(copyItems?.items);
      const data = copyItems?.items?.map((item) => ({
        inventoryItem: item?.inventoryItem?._id,
        quantity: item?.quantity,
        unit: item?.unit?._id,
      }));
      setmaterialsInItem(data);
    }
    setdishDescription(copyItems?.description);
  }, [copyItems]);

  //******** STEPS SETUP ******//
  const [setUp, setSetUp] = useState(true);
  const [setUp2, setSetUp2] = useState(false);
  const [setUp3, setSetUp3] = useState(false);

  const selectSetup = () => {
    setSetUp(true);
    setSetUp2(false);
    setSetUp3(false);
  };
  const selectSetup2 = () => {
    setSetUp(false);
    setSetUp2(true);
    setSetUp3(false);
  };
  const selectSetup3 = () => {
    setSetUp(false);
    setSetUp2(false);
    setSetUp3(true);
  };

  const handleReset = () => {
    setloader(false);
    setimage(null);
    setitemModal(false);
    setisEdit(false);
  };

  // *** ADD MENU ITEM ***//
  const handleAddItems = async () => {
    startApiCall(seterrorMessage, setloader);
    let uploadImg = await handleUploadImgFile(image);

    const data = {
      productName: dishName,
      items: materialsInItem,
      images: [uploadImg || ""],
      businessId: getCurrentWorkspaceId(),
      sellingPrice: sellingPrice,
      costPrice: costPrice,
      productCategory: id,
      description: dishDescription,
      isVeg: dishTypeVeg,
      avgCookingTimeInMin: timetoCook,
      createdBy: user?._id,
    };
    const res = await ApiCall(
      "post",
      ProductCategoryRelatedEndPoints.addMenuItem,
      data
    );
    if (res?.success) {
      toast.success("Item added to menu successfully");
      handleReset();
      handleGetCategoryItems();
    } else {
      setloader(false);
      seterrorMessage(res.error);
    }
  };

  // *** EDIT MENU ITEM ***//

  const handleEditItems = async () => {
    startApiCall(seterrorMessage, setloader);
    let uploadImg = await handleUploadImgFile(image);

    const data = {
      productName: dishName,
      productId: slectedItem?._id,
      items: materialsInItem,
      productCode: slectedItem?.productCode,
      images: [uploadImg],
      sellingPrice: sellingPrice,
      costPrice: costPrice,
      productCategory: id,
      description: dishDescription,
      isVeg: dishTypeVeg,
      avgCookingTimeInMin: timetoCook,
      actionBy: user?._id,
    };
    const res = await ApiCall(
      "post",
      ProductCategoryRelatedEndPoints.editMenuItem,
      data
    );
    if (res?.success) {
      toast.success("Item edited successfully");
      handleReset();
      handleGetCategoryItems();
    } else {
      setloader(false);
      seterrorMessage(res.error);
    }
  };

  useEffect(() => {
    if (isEdit === true) {
      setdishName(slectedItem?.productName);
      setimage(slectedItem?.images.length > 0 ? slectedItem?.images?.[0] : "");
      setmaterialsInTable(slectedItem?.items);
      setcostPrice(slectedItem?.costPrice);
      setsellingPrice(slectedItem?.sellingPrice);
      setdishDescription(slectedItem?.description);
      const mappedItems = slectedItem?.items?.map((item) => ({
        inventoryItem: item?.inventoryItem?._id,
        quantity: item?.quantity,
        unit: item?.unit?._id,
      }));

      setmaterialsInItem(mappedItems);
      setdishTypeVeg(slectedItem?.isVeg);
      setTimetoCook(slectedItem?.avgCookingTimeInMin);
    }
  }, [isEdit, slectedItem]);

  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block" }}
    >
      <div
        className="modal-dialog modal-dialog-centered modal-lg"
        role="document"
      >
        <div className="modal-content p-3">
          <div className="modal-body pb-0">
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                handleReset();
              }}
            >
              <span aria-hidden="true">×</span>
            </button>
            <span class="d-flex">
              <h5 class="text-capitalize mb-2">
                {isEdit ? "Edit Item" : "Add Item"}
              </h5>
              &nbsp;
            </span>
            <div className="border-bottom"></div>
            {setUp && !setUp2 && !setUp3 && (
              <AddItemStepOne
                setdishName={setdishName}
                setdishDescription={setdishDescription}
                dishName={dishName}
                dishDescription={dishDescription}
                setimage={setimage}
                image={image}
                dishTypeVeg={dishTypeVeg}
                setdishTypeVeg={setdishTypeVeg}
                selectSetup2={selectSetup2}
                slectedItem={slectedItem}
                isEdit={isEdit}
                completeFirstStep={completeFirstStep}
              />
            )}
            {!setUp && setUp2 && !setUp3 && (
              <AddItemStepTwo
                selectSetup3={selectSetup3}
                selectSetup={selectSetup}
                setmaterialsInItem={setmaterialsInItem}
                materialsInTable={materialsInTable}
                setmaterialsInTable={setmaterialsInTable}
                isEdit={isEdit}
                completeSecondStep={completeSecondStep}
                slectedItem={slectedItem}
                materialsInItem={materialsInItem}
                setCopyItems={setCopyItems}
              />
            )}
            {!setUp && !setUp2 && setUp3 && (
              <AddItemStepThree
                selectSetup2={selectSetup2}
                sellingPrice={sellingPrice}
                costPrice={costPrice}
                setsellingPrice={setsellingPrice}
                setcostPrice={setcostPrice}
                handleAddItems={handleAddItems}
                loader={loader}
                errorMessage={errorMessage}
                handleEditItems={handleEditItems}
                isEdit={isEdit}
                completeThirdStep={completeThirdStep}
                timetoCook={timetoCook}
                setTimetoCook={setTimetoCook}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddItems;
